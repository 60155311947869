<script setup>
import AppNotification from "~/components/notification/appNotification.vue";
import { useToast } from "~/composables/useToast";

const notifications = useState("notifications", () => []);
const toast = useToast();
</script>

<template>
  <div class="fixed top-4 md:top-6 lg:top-8 w-full z-100 pointer-events-none">
    <div class="w-full flex flex-col items-center justify-end">
      <div
        v-if="notifications.length"
        class="overflow-hidden flex flex-col gap-y-3 lg:gap-y-4"
      >
        <AppNotification
          v-for="notification in notifications"
          :key="notification.id"
          :title="notification.title"
          :text="notification.text"
          :variant="notification.variant"
          @close="() => toast.remove(notification.id)"
        />
      </div>
    </div>
  </div>
</template>
